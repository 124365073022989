import React from "react";
import NavOverlayElements from "./NavOverlayElements.js"

const NavOverlayMobile = ({image, ordinateNav}) => (
  <div className="overlayMobile">
    <div className="overlayGridMobile">
      <NavOverlayElements data={ordinateNav} type="mobile" image={image} />
    </div>
  </div>
)

export default NavOverlayMobile