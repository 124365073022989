import React from "react";
import {map, forOwn} from "lodash";
import "./GalleryFragment.js"
import "./Gallery.css";
import GalleryFragment from "./GalleryFragment.js";

const Gallery = data => {
  const formatData = (data) => { 
    let galleryImages = []

    forOwn(data, (element) => {
      if (Array.isArray(element)) {
        const images = []
        forOwn(element, (subElement) => {
          images.push({"id": subElement.id, "url": subElement.url, "thumbUrl": subElement.thumbUrl})
        });
        galleryImages.push(images)
      }
    })

    return galleryImages
  }  

  const makeThumbnailElement = (gallery) => (
    <div className="thumbnail" key={gallery[0].id}>
      <GalleryFragment gallery={gallery}/>
    </div>
  )

  return (
    <div className="detailGallery">
      <div className="thumbnails">
        {map(formatData(data.data), makeThumbnailElement)}
      </div>
    </div>
  );
}

export default Gallery