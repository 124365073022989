import React, {useState} from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import zoomImg from "../../images/zoom.svg";

const GalleryFragment = (gallery) => {
  const [openState, setOpenState] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const galleryList = gallery.gallery

  const DrawLightboxWhenOpen = () => {
    if (!openState) return null
    return (
      <Lightbox
        animationDuration={0}
        mainSrc={galleryList[photoIndex].url}
        nextSrc={galleryList[(photoIndex + 1) % galleryList.length].url}
        prevSrc={galleryList[(photoIndex + galleryList.length - 1) % galleryList.length].url}
        onCloseRequest={() => setOpenState(false) }
        onMovePrevRequest={() => setPhotoIndex((photoIndex + galleryList.length - 1) % galleryList.length)}
        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % galleryList.length)}
      />
    )
  }

  return (
    <>
      <img alt="" className="thumbnailImg" src={galleryList[0].thumbUrl} />
      <button className="zoomContainer" onClick={() => setOpenState(true)}>
        <h1 className="zoomTxt">Ava galerii</h1>
        <img alt="Zoom in" className="zoomImg" src={zoomImg} />
      </button>
      <DrawLightboxWhenOpen />
    </>
  )
}

export default GalleryFragment