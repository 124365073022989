import React, {useState} from "react";
import "./ExpandingTextbox.css";

const ExpandingTextbox = ({source}) => {
  const [FullLength, setLength] = useState(false);
  if (!source) return null
  const rakendContent = () => {
    const short = source.substring(0, source.length/2);
    return FullLength ? source : short
  }
  return (
    <div className="detailExpandingText">
      <h2 className="detailExpandingTextTitle">Rakenduslik Info</h2>
      <p className="detailExpandingTextContent">{rakendContent()}</p>
      <button 
        onClick={() => setLength(!FullLength)} 
        className="detailExpandingTextButton">{FullLength === true ? ("Näita vähem") : ("Loe edasi") }
      </button>
    </div>
  );
}

export default ExpandingTextbox
