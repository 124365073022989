import React from "react";
import { graphql, navigate } from "gatsby";
import { get } from "lodash";
import HTML2React from "html2react";
import classNames from "classnames";
import "./DetailPage.css";

import arrowSVGWhite from "../../images/arrowWhite.svg";
import Gallery from "../../components/Gallery/Gallery.js";
import ExpandingTextbox from "../../components/ExpandingTextbox/ExpandingTextbox.js";
import NavOverlay from "../../components/NavOverlay/NavOverlay.js";
import NavOverlayMobile from "../../components/NavOverlay/NavOverlayMobile.js";

const DetailPage = ({ data, location }) => {
  const currentPath = get(location, "pathname");
  const ordinatePreviewUrl = get(
    data,
    "wpgraphql.post.detailPage.ordinatePreview.sourceUrl"
  );
  return (
    <>
      <NavOverlay
        data={get(data, "wpgraphql.post")}
        menuData={get(data, "wpgraphql.menu")}
        currentPath={currentPath}
      />
      {/* Button only shows up when on mobile view. Sits still while navbar scrolls down */}
      <button
        className="backArrow backArrowStatic ordinateButton"
        onClick={() => navigate(-1)}
      >
        <div className="backArrowBackgroundCircle">
          <img alt="" src={arrowSVGWhite} className="arrowSVG" />
        </div>
      </button>
      <div className="detailWrapper">
        <h1 className="detailTitle">{get(data, "wpgraphql.post.title")}</h1>
        <p className="detailParentPage">
          {get(data, "wpgraphql.post.detailPage.parent")}
        </p>
        <div className="detailContent">
          {process.browser
            ? HTML2React(get(data, "wpgraphql.post.content"))
            : null}
        </div>
        <ExpandingTextbox
          source={get(data, "wpgraphql.post.detailPage.rakendinfo")}
          className="detailExpandingText"
        />
        <img
          className={classNames(
            "detailOrdinatePreview",
            !ordinatePreviewUrl && "hidden"
          )}
          src={ordinatePreviewUrl}
          alt="Asukohad Eesti kaardi peal"
        />
        <Gallery data={get(data, "wpgraphql.post.detailPage")} />
        <NavOverlayMobile
          image={ordinatePreviewUrl}
          ordinateNav={get(data, "wpgraphql.post")}
        />
      </div>
    </>
  );
};

export default DetailPage;

export const query = graphql`
  query($id: ID!) {
    wpgraphql {
      post(id: $id) {
        content
        title
        detailPage {
          parent
          rakendinfo
          metsGalerii1 {
            id
            thumbUrl: sourceUrl(size: MEDIUM_LARGE)
            url: sourceUrl(size: LARGE)
          }
          metsGalerii2 {
            id
            thumbUrl: sourceUrl(size: MEDIUM_LARGE)
            url: sourceUrl(size: LARGE)
          }
          metsGalerii3 {
            id
            thumbUrl: sourceUrl(size: MEDIUM_LARGE)
            url: sourceUrl(size: LARGE)
          }
          ordinatePreview {
            id
            sourceUrl
          }
          navigateup
          navigateright
          navigateleft
          navigatedown
        }
        categories {
          nodes {
            name
          }
        }
      }
      menu(id: "bmF2X21lbnU6Nw==") {
        name
        menuItems(first: 100, where: { parentId: "null" }) {
          nodes {
            label
            id
            childItems {
              nodes {
                label
                url
                id
                parentId
                childItems {
                  nodes {
                    label
                    url
                    id
                    parentId
                    childItems {
                      nodes {
                        label
                        url
                        id
                        parentId
                        childItems {
                          nodes {
                            label
                            url
                            id
                            parentId
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
